import type { AppProps } from "next/app";
import { WithThemeProvider } from "@providers/ThemeProvider";
import "public/styles/global.css";
import "public/styles/datepicker.css";
import { withHydrate } from "effector-next";
import { NextPage } from "next";
import { ReactElement, ReactNode } from "react";
import { appWithTranslation, useTranslation } from "next-i18next";
import NextNProgress from "nextjs-progressbar";
import Head from "next/head";

const enhance = withHydrate();

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t(`БНК Финанс Казахстан`)}</title>
        <meta name="description" content={t("meta-description")} />
      </Head>
      <WithThemeProvider>
        <NextNProgress color="#B43432" options={{ showSpinner: false }} />
        {getLayout(<Component {...pageProps} />)}
      </WithThemeProvider>
    </>
  );
}

export default enhance(appWithTranslation(App));
