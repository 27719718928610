import React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./globalstyles";
import theme from "./theme";

export interface IProps {
  children: React.ReactNode;
}

export function WithThemeProvider({ children }: IProps) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}
