const theme = {
  colors: {
    primary_red: "#B43432",
    primary_dark_red: "#6B1F1D",
    primary_dark_gray: "#5A5449",
    primary_light_gray: "#ACA290",
    metallic_silver: "#878B88",
    metallic_gold: "#7A674B",
    secondary_error: "#F63A2E",
    secondary_error_1: "#E53535",
    secondary_error_2: "#FF5C5C",
    secondary_error_3: "#FF8080",
    secondary_warning: "#FFDB36",
    secondary_warning_1: "#FFDB36",
    secondary_warning_2: "#FDDD48",
    secondary_warning_3: "#FDED72",
    secondary_info: "#2C6AE3",
    secondary_info_1: "#004FC4",
    secondary_info_2: "#5B8DEF",
    secondary_info_3: "#9DBFF9",
    secondary_success: "#39CE48",
    secondary_success_1: "#05A660",
    secondary_success_2: "#39D98A",
    secondary_success_3: "#57EBA1",
    basic_black: "#3F3F3F",
    basic_gray_1: "#E9E9E9",
    basic_gray_2: "#F2F2F5",
    basic_white: "#FFFFFF",
  },
  spacing: 8,
  sizes: {
    1120: "1120px",
    1440: "1440px",
    928: "928px",
    tablet: "991px",
    tabletMin: "768px",
    mobile: "576px",
  },
  shadow: {
    "10dp": "0px 4px 10px rgba(0, 0, 0, 0.05)",
    "20dp": "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "30dp": "0px 4px 30px rgba(91, 94, 190, 0.05)",
  },
};

export type themeTypes = typeof theme;
export type ThemeColorsType = keyof typeof theme.colors;
export type ThemeSizesType = keyof typeof theme.sizes;

export default theme;
